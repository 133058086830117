import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Corporate } from './Corporate';
import { Individual } from './Individual';
import { ACCOUNT_TYPE, KYC_PROVIDERS, KYC_STATUSES } from 'components/common/constants';
import NoOutlet from 'components/common/NoOutlet/NoOutlet';
import Shufti from 'components/Verification/Shufti';
import Sumsub from 'components/Verification/Sumsub';
import { ROUTE_PATHS } from 'routes/constants';
import './Verification.scss';

const Verification = ({ kycStatus, userStore }) => {
  const [isBeginVerification, setIsBeginVerification] = useState(false);
  const [applicantLevel, setApplicantLevel] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const kycProvider = process.env.REACT_APP_CORPORATE_KYC_PROVIDER;

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.DASHBOARD) {
      setIsBeginVerification(false);
    } else if (location.pathname === ROUTE_PATHS.VERIFICATION && !isBeginVerification) {
      navigate('/dashboard');
    }
  }, [location.pathname, isBeginVerification, navigate]);

  const handleOpenVerification = (level) => {
    navigate('/verification');
    setApplicantLevel(level);
    process.env.REACT_APP_KYC_BUTTON_LINK
      ? window.open(process.env.REACT_APP_KYC_BUTTON_LINK, '_blank').focus()
      : setIsBeginVerification(true);
  };

  const isUnderReview = kycStatus === KYC_STATUSES.UNDER_REVIEW;
  const isIndividual = userStore?.userData.account?.type === ACCOUNT_TYPE.INDIVIDUAL;
  const accountNumber = userStore?.userData.account?.account_number;
  const verificationType = userStore?.userData.account?.kyc_level_name;
  const beginVerification = async (accountNumber, data) => await userStore?.beginVerification(accountNumber, data);

  if (kycStatus === KYC_STATUSES.DECLINED && !isBeginVerification) {
    return (
      <NoOutlet
        error={{ code: KYC_STATUSES.DECLINED }}
        continueVerification={handleOpenVerification}
        verificationType={verificationType}
      />
    );
  }

  return (
    <>
      {!isBeginVerification && isIndividual && (
        <Individual handleOpenVerification={handleOpenVerification} isUnderReview={isUnderReview} />
      )}
      {!isBeginVerification && !isIndividual && (
        <Corporate
          handleOpenVerification={handleOpenVerification}
          verificationType={verificationType}
          isUnderReview={isUnderReview}
        />
      )}
      {isBeginVerification && kycProvider === KYC_PROVIDERS.SHUFTI && <Shufti accountNumber={accountNumber} />}
      {isBeginVerification && kycProvider === KYC_PROVIDERS.SUMSUB && (
        <Sumsub
          email={userStore?.userData.account?.email}
          phone={userStore?.userData.phone}
          accountNumber={accountNumber}
          levelName={applicantLevel}
          accessToken={userStore?.verificationAccessToken}
          beginVerification={beginVerification}
        />
      )}
    </>
  );
};

Verification.propTypes = {
  userStore: MobXPropTypes.observableObject,
  kycStatus: PropTypes.string
};

export default inject((stores) => ({
  userStore: stores.userStore
}))(observer(Verification));
