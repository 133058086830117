/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import i18nContext from 'components/i18n-context';
import { JURISDICTION_CODES } from 'components/common/constants';
import Loader from 'components/common/Loader';
import { ROUTE_PATHS } from 'routes/constants';
import Alert from 'uikit/Alert/Alert';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import Input from 'uikit/Input/Input';
import { InputDropDownSearch } from 'uikit/InputDropDown/InputDropDownSearch';
import './Verification.scss';

const Shufti = ({ accountNumber, userStore }) => {
  const i18n = useContext(i18nContext);

  const form = useFormik({
    validateOnChange: false,
    initialValues: {
      registrationNumber: '',
      jurisdiction: ''
    },
    validationSchema: Yup.object({
      registrationNumber: Yup.string()
        .nullable(true)
        .max(255, i18n.getMessage('error.fieldSize', { amount: '255' }))
        .required(i18n.getMessage('verification.shufti.error.isRequired')),
      jurisdiction: Yup.string()
        .nullable(true)
        .max(5, i18n.getMessage('verification.shufti.error.maxSize'))
        .min(2, i18n.getMessage('verification.shufti.error.minSize'))
        .required(i18n.getMessage('verification.shufti.error.isRequired'))
    }),
    onSubmit: async (values) => {
      await userStore.beginVerification(accountNumber, {
        company_registration_number: values.registrationNumber,
        company_jurisdiction_code: values.jurisdiction
      });
    }
  });

  const { values, errors, handleSubmit, handleChange, submitCount } = form;

  const handleJurisdictionChange = (name, data) => handleChange({ target: { name, value: data } });

  const jurisdictionOptions = JURISDICTION_CODES.map((code) => {
    return {
      key: code,
      value: i18n.getMessage(`jurisdictionCountry.${code}`)
    };
  });

  return (
    // <section className='right-section'>
    <Container className='shufti-verification-container' header={i18n.getMessage('container.companyVerification')}>
      <div className='shufti-verification-wrapper'>
        <form action='#' id='send' onSubmit={handleSubmit} className='shufti-verification-form'>
          <Alert
            className='send-money-alert'
            type={'warning'}
            message={
              userStore.error ? (
                <>
                  {i18n.getMessage('verification.shufti.apiError.message')}
                  <a href={ROUTE_PATHS.MESSAGES} className='link-normal'>
                    {i18n.getMessage('verification.shufti.apiError.support')}
                  </a>
                </>
              ) : (
                ''
              )
            }
          />
          <Input
            isRequired={true}
            type={'text'}
            label={i18n.getMessage('verification.shufti.label.registrationNumber')}
            name={'registrationNumber'}
            value={values.registrationNumber}
            onChange={handleChange}
            initialStatus={submitCount}
            autoFocus={true}
            error={errors?.registrationNumber || !!userStore.error}
          />
          <InputDropDownSearch
            isRequired={true}
            label={i18n.getMessage('verification.shufti.label.jurisdiction')}
            name={'jurisdiction'}
            value={values.jurisdiction}
            initialStatus={submitCount}
            onChange={handleJurisdictionChange}
            options={jurisdictionOptions}
            error={errors?.jurisdiction || !!userStore.error}
          />
          <Button
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            fullWidth={false}
            onClick={() => {}}
            isDisabled={userStore.isVerificationLoading}
          >
            {userStore.isVerificationLoading ? <Loader /> : i18n.getMessage('verification.shufti.button.submit')}
          </Button>
        </form>
      </div>
    </Container>
    // </section>
  );
};

Shufti.propTypes = {
  accountNumber: PropTypes.string,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userStore: stores.userStore
}))(observer(Shufti));
