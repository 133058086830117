import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { ReactComponent as CurrencyExchangeSwitchIcon } from 'assets/switch-icon.svg';
import AmountInput from 'components/common/AmountInput';
import { CURRENCY_SYMBOLS } from 'components/common/constants';
import { filterPaymentWallets } from 'components/common/PaymentForm/utils';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import i18nContext from 'components/i18n-context';
import { getWalletTitle } from 'components/utils';
import {
  amountFormattedValue,
  getErrorMessageForAlert,
  findWalletByCurrency,
  prepareFieldErrors
} from 'services/utils';
import Alert from 'uikit/Alert/Alert';
import Button from 'uikit/Button/Button';
import { Container } from 'uikit/Container/Container';
import { IconButton } from 'uikit/IconButton/IconButton';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import { PopUp } from 'uikit/PopUp/PopUp';
import './CurrencyExchange.scss';

const EXCHANGE_RATE_PRECISION = 4;

const CurrencyExchange = ({ userWallets, accountNumber, currencyExchangeStore, userStore }) => {
  const i18n = useContext(i18nContext);
  const getServerFieldsErrors = (variable) => prepareFieldErrors(i18n, currencyExchangeStore.error, variable);

  useEffect(() => {
    return () => {
      currencyExchangeStore.resetExchangeStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const paymentWallets = filterPaymentWallets(userWallets);

    if (paymentWallets.length > 0) {
      const walletFrom = findWalletByCurrency({ wallets: paymentWallets });
      const walletTo = findWalletByCurrency({ wallets: paymentWallets, currencyForExclude: walletFrom?.currency });
      currencyExchangeStore.setFromToWallets(walletFrom, walletTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWallets]);

  useEffect(() => {
    if (currencyExchangeStore.isExchangeCreateSuccess) {
      userStore.getUserWallets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyExchangeStore.isExchangeCreateSuccess]);

  useEffect(() => {
    if (currencyExchangeStore.walletFrom && currencyExchangeStore.walletTo) {
      currencyExchangeStore.getExchangeRate();
      getCommission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyExchangeStore.walletFrom, currencyExchangeStore.walletTo]);

  const handleChangeWalletFrom = (name, value) => {
    const paymentWallets = filterPaymentWallets(userWallets);
    const selectedWallet = paymentWallets.find((wallet) => wallet.wallet_number === value);
    const walletTo =
      currencyExchangeStore.walletTo?.currency === selectedWallet.currency
        ? findWalletByCurrency({ wallets: paymentWallets, currencyForExclude: selectedWallet.currency })
        : currencyExchangeStore.walletTo;
    currencyExchangeStore.setFromToWallets(selectedWallet, walletTo);
  };

  const handleChangeWalletTo = (name, value) => {
    const paymentWallets = filterPaymentWallets(userWallets);
    const selectedWallet = paymentWallets.find((wallet) => wallet.wallet_number === value);
    const walletFrom =
      currencyExchangeStore.walletFrom?.currency === selectedWallet.currency
        ? findWalletByCurrency({ wallets: paymentWallets, currencyForExclude: selectedWallet.currency })
        : currencyExchangeStore.walletFrom;
    currencyExchangeStore.setFromToWallets(walletFrom, selectedWallet);
  };

  const handleAmountChange = ({ target: { name, value } }) => {
    currencyExchangeStore.setFromToAmount(name, value);
  };

  const handleCurrencyExchangeSwitch = () => {
    currencyExchangeStore.setFromToWallets(currencyExchangeStore.walletTo, currencyExchangeStore.walletFrom);
  };

  const getAvailableBalance = (wallet) =>
    wallet &&
    i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(wallet.available),
      currency: wallet.currency
    });

  const walletsOptions = filterPaymentWallets(userWallets)?.map((wallet) => {
    const title = getWalletTitle(wallet);

    return {
      key: wallet?.wallet_number,
      value: title,
      currency: wallet?.currency
    };
  });

  const getCommission = async () => {
    if (
      currencyExchangeStore.amountFrom &&
      currencyExchangeStore.amountTo &&
      currencyExchangeStore.walletFrom &&
      currencyExchangeStore.walletTo
    ) {
      await currencyExchangeStore?.exchangeCurrency(accountNumber, true);
    }
  };

  const isWalletsExist = currencyExchangeStore.walletFrom && currencyExchangeStore.walletTo;

  return (
    <section className='right-section currency-exchange'>
      <Container header={i18n.getMessage('container.exchange')}>
        <div className='exchange-wrapper'>
          <Alert
            type={
              (currencyExchangeStore?.error && !currencyExchangeStore?.error?.type) || !isWalletsExist
                ? 'warning'
                : 'info'
            }
            className='exchange-wrapper-alert'
            message={
              !isWalletsExist
                ? i18n.getMessage('transfer.provider.notFound')
                : currencyExchangeStore?.error && !currencyExchangeStore?.error?.type
                ? getErrorMessageForAlert(i18n, currencyExchangeStore.error)
                : i18n.getMessage('currencyExchange.announcement')
            }
          />
          <div className='exchange-form-wrapper'>
            <div className='exchange-inputs-block'>
              <InputDropDown
                isRequired={true}
                name='walletFrom'
                topLabel={getAvailableBalance(currencyExchangeStore.walletFrom)}
                value={currencyExchangeStore.walletFrom?.wallet_number}
                currency={currencyExchangeStore.walletFrom?.currency}
                options={walletsOptions}
                onChange={handleChangeWalletFrom}
                label={i18n.getMessage('currencyExchange.form.from')}
                isMulti={false}
              />
              <AmountInput
                isRequired={true}
                label={i18n.getMessage('currencyExchange.form.sellAmount', {
                  minimum: amountFormattedValue(currencyExchangeStore.exchangeRate?.min)
                })}
                name='amountFrom'
                value={currencyExchangeStore.amountFrom}
                // eslint-disable-next-line max-len
                subText={`1${CURRENCY_SYMBOLS[currencyExchangeStore.walletFrom?.currency]} = ${amountFormattedValue(
                  currencyExchangeStore.exchangeRate?.rate,
                  EXCHANGE_RATE_PRECISION
                )}${CURRENCY_SYMBOLS[currencyExchangeStore.walletTo?.currency]}`}
                onChange={handleAmountChange}
                placeholder={'00.00'}
                error={getServerFieldsErrors(amountFormattedValue(currencyExchangeStore.exchangeRate?.min)).amount}
                onBlur={getCommission}
              />
            </div>
            <IconButton
              className={'switch-button'}
              Icon={CurrencyExchangeSwitchIcon}
              onClick={handleCurrencyExchangeSwitch}
            />
            <div className='exchange-inputs-block'>
              <InputDropDown
                isRequired={true}
                name='walletTo'
                topLabel={getAvailableBalance(currencyExchangeStore.walletTo)}
                value={currencyExchangeStore.walletTo?.wallet_number}
                currency={currencyExchangeStore.walletTo?.currency}
                options={walletsOptions}
                onChange={handleChangeWalletTo}
                label={i18n.getMessage('currencyExchange.form.to')}
                isMulti={false}
              />
              <AmountInput
                isRequired={true}
                label={i18n.getMessage('currencyExchange.form.buyAmount')}
                // eslint-disable-next-line max-len
                subText={`1${CURRENCY_SYMBOLS[currencyExchangeStore.walletTo?.currency]} = ${amountFormattedValue(
                  1 / currencyExchangeStore.exchangeRate?.rate,
                  EXCHANGE_RATE_PRECISION
                )}${CURRENCY_SYMBOLS[currencyExchangeStore.walletFrom?.currency]}`}
                name='amountTo'
                value={currencyExchangeStore.amountTo}
                placeholder={'00.00'}
                onChange={handleAmountChange}
                onBlur={getCommission}
              />
            </div>
            <div className='exchange-rate-commission-wrapper'>
              <div className='exchange-rate-commission-block'>
                <span className='exchange-rate-commission-title'>
                  {i18n.getMessage('currencyExchange.form.yourRate')}
                </span>
                <span className='exchange-rate-commission-value'>
                  {`1 ${currencyExchangeStore.walletFrom?.currency} = ${amountFormattedValue(
                    1 / currencyExchangeStore.exchangeRate?.rate,
                    EXCHANGE_RATE_PRECISION
                  )} ${currencyExchangeStore.walletTo?.currency}`}
                </span>
              </div>
              <div className='exchange-rate-commission-block'>
                <span className='exchange-rate-commission-title'>
                  {i18n.getMessage('currencyExchange.form.commission', {
                    sellCurrency: currencyExchangeStore.walletFrom?.currency,
                    buyCurrency: currencyExchangeStore.walletTo?.currency
                  })}
                </span>
                <span className='exchange-rate-commission-value'>
                  {amountFormattedValue(currencyExchangeStore?.exchangeData?.total_commissions)}
                </span>
              </div>
            </div>
          </div>
          {isWalletsExist && (
            <div className='exchange-button-wrapper'>
              <Button
                type={'primary'}
                roleType={'submit'}
                size={'large'}
                isDisabled={
                  !!currencyExchangeStore?.error ||
                  !currencyExchangeStore.amountFrom ||
                  !currencyExchangeStore.exchangeData ||
                  currencyExchangeStore.isCommissionLoading
                }
                onClick={() => currencyExchangeStore?.exchangeCurrency(accountNumber)}
              >
                {i18n.getMessage('currencyExchange.form.exchangeNow')}
              </Button>
            </div>
          )}
        </div>
      </Container>
      <PopUp
        className={currencyExchangeStore?.isExchangeCreateSuccess ? 'transaction-success' : 'transaction-info'}
        show={currencyExchangeStore?.isExchangeConfirmSuccess || currencyExchangeStore?.isExchangeCreateSuccess}
        alignOnCenter={currencyExchangeStore?.isExchangeCreateSuccess}
        onClose={
          currencyExchangeStore?.isExchangeConfirmSuccess
            ? () => currencyExchangeStore?.resetConfirmSuccess()
            : () => currencyExchangeStore?.resetCreateSuccess()
        }
      >
        {currencyExchangeStore?.isExchangeCreateSuccess ? (
          <PopUpSuccessScheme
            onClose={() => currencyExchangeStore.resetCreateSuccess()}
            message={i18n.getMessage('popUp.message.moneyTransfer')}
          />
        ) : (
          <TransferConfirmationScheme
            isCurrency={true}
            transferData={currencyExchangeStore?.exchangeData}
            error={currencyExchangeStore?.confirmationPopupError}
            generateSecurityCode={() => currencyExchangeStore.generateExchangeSecurityCode()}
            resendSecurityCode={() => currencyExchangeStore.resendExchangeSecurityCode()}
            clearError={() => currencyExchangeStore?.clearConfirmationPopupError()}
            onConfirm={currencyExchangeStore?.confirmExchangeCurrency(accountNumber)}
            onClose={() => currencyExchangeStore?.resetConfirmSuccess()}
          />
        )}
      </PopUp>
    </section>
  );
};

CurrencyExchange.propTypes = {
  userWallets: PropTypes.array,
  accountNumber: PropTypes.string,
  currencyExchangeStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  userWallets: stores.userStore?.userWallets,
  accountNumber: stores.userStore.userData.account?.account_number,
  currencyExchangeStore: stores.currencyExchangeStore,
  userStore: stores.userStore
}))(observer(CurrencyExchange));
