/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SumsubWebSdk from '@sumsub/websdk-react';
import PopUpSuccessScheme from 'components/common/PopUpScheme/PopUpSuccessScheme';
import i18nContext from 'components/i18n-context';
import { setUnderReviewStatus } from 'services/requestAgent';
import { Container } from 'uikit/Container/Container';
import { PopUp } from 'uikit/PopUp/PopUp';
import './Verification.scss';

const Sumsub = ({ email, phone, accountNumber, levelName, accessToken, beginVerification }) => {
  const i18n = useContext(i18nContext);
  const [isApplicantSubmitted, setIsApplicantSubmitted] = useState(false);

  useEffect(() => {
    getAccessToken();
  }, []);

  const getAccessToken = async () => {
    await beginVerification(accountNumber, { level_name: levelName });
  };

  const handleSubmittedSuccessOk = () => setIsApplicantSubmitted(false);

  const handleOnMessage = async (type) => {
    if (type === 'idCheck.onApplicantSubmitted') {
      await setUnderReviewStatus(accountNumber);
      setIsApplicantSubmitted(true);
    }
    if (type === 'idCheck.onApplicantResubmitted') {
      setIsApplicantSubmitted(true);
    }
  };

  return (
    <section className='right-section'>
      <Container className='send-money' header={i18n.getMessage('container.verification')}>
        <div className='sumsub'>
          {accessToken && (
            <SumsubWebSdk
              accessToken={accessToken}
              expirationHandler={getAccessToken}
              config={{
                email: email,
                phone: phone
              }}
              onMessage={handleOnMessage}
            />
          )}
        </div>
        <PopUp show={isApplicantSubmitted} alignOnCenter>
          <PopUpSuccessScheme
            message={i18n.getMessage('verification.submitted.success.message')}
            onClose={handleSubmittedSuccessOk}
          />
        </PopUp>
      </Container>
    </section>
  );
};

Sumsub.propTypes = {
  email: PropTypes.string,
  accountNumber: PropTypes.string,
  levelName: PropTypes.string,
  accessToken: PropTypes.string,
  beginVerification: PropTypes.func
};

export default Sumsub;
