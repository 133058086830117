import { makeAutoObservable, runInAction } from 'mobx';
import {
  getUserInformation,
  getWallets,
  getApplicationState,
  getTariffs,
  getUserInfoByAccNumber,
  enableGoogle2FARequest,
  disableGoogle2FARequest,
  confirmGoogle2FARequest,
  beginVerificationRequest
} from 'services/requestAgent';
import {
  setSelectedMultiAccount,
  getSelectedMultiAccount,
  setSecureUserPhone,
  setConfirmationActionType
} from 'services/authUtils';
import {
  WALLET_PROPERTIES,
  ACCOUNT_LEVEL,
  ACCOUNT_TYPE,
  USER_ACCOUNT_STATUSES,
  SETTINGS_TABS,
  CONFIRMATION_TYPES
} from 'components/common/constants';

class UserStore {
  isLoading = false;
  isVerificationLoading = false;
  error = null;
  userData = {};
  userWallets = [];
  currentWallet = null;
  activeAccount = null;
  appFeatures = {};
  appConstants = {};
  isAccountVerified = null;
  enabledTariffs = [];
  currentAccountPermission = null;
  isShowGoogle2FaPopUp = false;
  isGoogle2FaEnablingSuccess = false;
  isGoogle2FaDisablingConfirmation = false;
  isGoogle2FaDisablingSuccess = false;
  isGoogle2FAEnabled = false;
  googleQrUrl = null;
  googleUid = null;
  settingsActiveTab = SETTINGS_TABS.PASSWORD_RECOVERY;
  verificationAccessToken = null;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setIsVerificationLoading(status) {
    this.isVerificationLoading = status;
    this.error = null;
  }

  setSettingsActiveTab(tab) {
    this.settingsActiveTab = tab;
  }

  getVerifiedStatus(userData) {
    if (process.env.REACT_APP_KYC_MODE !== '/external') return true;

    if (userData.account.type === ACCOUNT_TYPE.COMPANY) {
      return userData.account.status === USER_ACCOUNT_STATUSES.VERIFIED;
    }

    if (userData.account?.type === ACCOUNT_TYPE.INDIVIDUAL) {
      return userData.account?.level !== ACCOUNT_LEVEL.ZERO;
    }
  }

  setActiveAccount(account) {
    this.activeAccount = this.userWallets.find((wallet) => wallet[WALLET_PROPERTIES.WALLET_NUMBER] === account);
  }

  setUserData(data) {
    this.userData = data;
  }

  setWallets(wallets) {
    this.userWallets = wallets;
  }

  setCurrentWallet(wallet) {
    this.currentWallet = wallet;
  }

  closeIsShowGoogle2FaPopUp() {
    this.isShowGoogle2FaPopUp = false;
    this.googleUid = null;
    this.googleQrUrl = null;
  }

  setIsGoogle2FaEnablingSuccess(status) {
    this.isGoogle2FaEnablingSuccess = status;
  }

  setIsGoogle2FaDisablingConfirmation(status) {
    this.isGoogle2FaDisablingConfirmation = status;
  }

  setIsGoogle2FaDisablingSuccess(status) {
    this.isGoogle2FaDisablingSuccess = status;
  }

  resetEnabledTariffs() {
    this.enabledTariffs = [];
  }

  updateRepresentativeData(updatedRepresentative, isRemove = false) {
    const existingRepresentative = this.userData.representatives?.find(({ id }) => id === updatedRepresentative.id);

    this.userData.representatives = existingRepresentative
      ? this.userData.representatives.reduce((result, representative) => {
          if (representative.id === updatedRepresentative.id) {
            if (isRemove) return result;
            result.push(updatedRepresentative);
            return result;
          }
          result.push(representative);
          return result;
        }, [])
      : [...this.userData.representatives, updatedRepresentative];
  }

  getCurrentAccountPermissions(availableAccounts, accountNumber) {
    return (
      availableAccounts.find(
        (account) =>
          account.account_number === accountNumber || account?.customer_account?.account_number === accountNumber
      )?.permissions || null
    );
  }

  hasPermissions = (expectedPermissions = []) => {
    const userPermissions = this.currentAccountPermission;
    // When we don't have permission in account object -> main account
    if (!userPermissions) {
      return true;
    }
    return !!userPermissions?.filter((permission) => expectedPermissions.includes(permission)).length;
  };

  changeCurrentUserAccount = async (accountNumber) => {
    this.currentAccountPermission = this.getCurrentAccountPermissions(this.userData.availableAccounts, accountNumber);
    setSelectedMultiAccount(accountNumber);
    await this.loadUserData(accountNumber);
  };

  async getAppState() {
    try {
      this.setIsLoading(true);
      const data = await getApplicationState();
      runInAction(() => {
        this.appConstants = data.constants;
        this.appFeatures = data.features;
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  getCurrentAccount(selectedSharedAccountData, ownUserAccounts) {
    if (selectedSharedAccountData) {
      return selectedSharedAccountData;
    }
    return ownUserAccounts.find(({ type }) => type === ACCOUNT_TYPE.COMPANY) || ownUserAccounts[0];
  }

  async loadUserData(accountNumber, isAfterSuccessfulRegistration) {
    this.setIsLoading(true);
    try {
      this.setIsLoading(true);
      let selectedSharedAccountData = null;
      const data = await getUserInformation();
      if (data?.user_info?.phone) {
        setSecureUserPhone(data?.user_info?.phone);
      }
      const selectedAccountNumber = isAfterSuccessfulRegistration
        ? data.user_accounts[data.user_accounts.length - 1]?.account_number
        : accountNumber || getSelectedMultiAccount() || null;
      if (selectedAccountNumber) {
        selectedSharedAccountData = await getUserInfoByAccNumber(selectedAccountNumber);
      }
      // DTO changed for UI representation (past representation without multiaccounts)
      const userDataRepresentation = {
        account: this.getCurrentAccount(selectedSharedAccountData, data.user_accounts),
        representatives: data.representatives,
        availableAccounts: [...data.user_accounts, ...data.shared_accounts],
        ...data.user_info
      };

      const currentAccountPermission = this.getCurrentAccountPermissions(
        userDataRepresentation.availableAccounts,
        selectedAccountNumber
      );

      // Move wallets logic to separate store!
      if (!userDataRepresentation?.account?.account_number) {
        return (this.error = { code: 'ACCOUNT_NOT_REGISTERED' });
      }
      const wallets = await getWallets(userDataRepresentation.account.account_number);

      runInAction(() => {
        setConfirmationActionType(
          userDataRepresentation.two_factor_auth_enabled ? CONFIRMATION_TYPES.GOOGLE2FA : CONFIRMATION_TYPES.PHONE
        );
        this.userData = userDataRepresentation;
        this.isGoogle2FAEnabled = userDataRepresentation?.two_factor_auth_enabled;
        this.currentAccountPermission = currentAccountPermission;
        this.userWallets = wallets;
        this.currentWallet = wallets[0];
        this.isAccountVerified = this.getVerifiedStatus(userDataRepresentation);
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isLoading = false;
      });
      throw err;
    }
  }

  async loadEnabledTariffs(walletNumber, transactional) {
    try {
      // Move tariffs logic to separate store as well likely
      const data = await getTariffs(this.userData.account?.account_number, walletNumber, transactional);
      runInAction(() => {
        this.enabledTariffs = data;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    }
  }

  // Temporary solution for updating balances
  async getUserWallets() {
    try {
      const wallets = await getWallets(this.userData.account.account_number);
      runInAction(() => {
        this.userWallets = wallets;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
      throw err;
    }
  }

  isFeesEnabled() {
    return !!this.appFeatures.tariffsEnabled;
  }

  isCardsEnabled() {
    return !!this.appFeatures.cardsEnabled;
  }

  isRepresentativesEnabled() {
    return !!this.appFeatures.representativesEnabled;
  }

  async enableGoogle2FA() {
    this.setIsLoading(true);
    try {
      const { url, confirmation_uid } = await enableGoogle2FARequest();

      runInAction(() => {
        this.googleQrUrl = url;
        this.googleUid = confirmation_uid;
        this.isShowGoogle2FaPopUp = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async confirmGoogle2FAEnabling(code) {
    this.setIsLoading(true);
    try {
      await confirmGoogle2FARequest({ uid: this.googleUid, code });

      runInAction(() => {
        setConfirmationActionType(CONFIRMATION_TYPES.GOOGLE2FA);
        this.googleUid = null;
        this.googleQrUrl = null;
        this.isGoogle2FaEnablingSuccess = true;
        this.isShowGoogle2FaPopUp = false;
        this.isGoogle2FAEnabled = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async disableGoogle2FA() {
    this.setIsLoading(true);
    try {
      await disableGoogle2FARequest();

      runInAction(() => {
        setConfirmationActionType(CONFIRMATION_TYPES.PHONE);
        this.isGoogle2FAEnabled = false;
        this.isGoogle2FaDisablingConfirmation = false;
        this.isGoogle2FaDisablingSuccess = true;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async beginVerification(accountNumber, data) {
    this.setIsVerificationLoading(true);
    try {
      const { token: accessToken, kyc_status: kycStatus } = await beginVerificationRequest(accountNumber, data);

      runInAction(() => {
        this.verificationAccessToken = accessToken ? accessToken : null;
        if (this.userData?.account) {
          this.userData.account.kyc_status = kycStatus ? kycStatus : null;
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isVerificationLoading = false;
      });
    }
  }
}

export default new UserStore();
